


















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Customer } from '@/models';
import Fab from '@/components/Fab.vue';
import { debounce } from 'lodash-es';
import { CustomerService } from '@/lib/services';
import MergeConfirmationModal from '@/components/modals/MergeConfirmationModal.vue';
import Invoices from '@/components/archive/Invoices.vue';
import { Tab, TabBar } from '@/components/tabbar';
import SepaCollections from '@/components/archive/SepaCollections.vue';
import DuplicatesTable from '@/views/DuplicatesTable.vue';

type MergePayload = {
  master: number;
  copies: number[];
};

@Component({
  components: {
    DuplicatesTable,
    TabBar,
    SepaCollections,
    Tab,
    Invoices,
    MergeConfirmationModal,
    Fab,
  },
})
export default class CustomersMerge extends Vue {
  customers: Customer[] = [];
  customerGroups: Map<string, Customer[]> = new Map();
  mergeSelection: number[] = [];
  searchString: string = '';
  showConfirmation: boolean = false;

  type: string = 'email';

  tabOptions = [
    { type: 'email', label: 'customer.merger.by-email' },
    { type: 'name', label: 'customer.merger.by-name' },
  ];

  updatePage = debounce(() => this.updateList(), 500);

  created(): void {
    this.updateList();
  }

  public async updateList(): Promise<void> {
    const groups = await CustomerService.allMergeGroups(
      this.searchString,
      this.type,
    );

    this.mergeSelection = [];
    this.customers = [];
    this.customerGroups = new Map();
    Object.entries(groups)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => b.value.length - a.value.length)
      .forEach((object) => {
        this.customers.push(...object.value);
        this.customerGroups.set(object.key, object.value);
      });
  }

  get mergeCustomers(): Customer[] {
    return this.customers.filter((c) => this.mergeSelection.includes(c.id));
  }

  get mergeDisabled(): boolean {
    return this.mergeSelection.length < 2;
  }

  onTabSelect(idx: number): void {
    this.type = this.tabOptions[idx].type;
    this.updateList();
  }

  select(customer: Customer): void {
    if (this.mergeSelection.includes(customer.id)) {
      this.mergeSelection = this.mergeSelection.filter(
        (id) => id !== customer.id,
      );
    } else {
      this.mergeSelection.push(customer.id);
    }
  }

  async merge(payload: MergePayload): Promise<void> {
    try {
      await CustomerService.mergeCustomers(payload.master, payload.copies);
    } catch (e) {
      this.mergeSelection.splice(0, this.mergeSelection.length);
    }
    this.mergeSelection.splice(0, this.mergeSelection.length);
    await this.updateList();
  }
}
