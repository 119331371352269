

































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Customer } from '@/models';

@Component({})
export default class DuplicatesTable extends Vue {
  @Prop({ required: true })
  customerGroups!: Map<string, Customer[]>;
  @Prop({ required: true })
  mergeSelection!: number[];

  isSelected(customer: Customer): boolean {
    return this.mergeSelection.includes(customer.id);
  }

  isGroupSelected(group: Customer[]): boolean {
    return group.every((customer) => this.isSelected(customer));
  }

  toggleGroup(group: Customer[]): void {
    if (this.isGroupSelected(group)) {
      group.forEach((customer) => this.$emit('select', customer));
    } else {
      group.forEach((customer) => {
        if (!this.isSelected(customer)) {
          this.$emit('select', customer);
        }
      });
    }
  }
}
