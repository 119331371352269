




























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Customer } from '@/models';

@Component({})
export default class MergeConfirmationModal extends Vue {
  @Prop({ default: () => [] })
  customers: Customer[];

  masterCustomer: number | null = null;

  created(): void {
    if (this.customers.length === 0) return;

    const sortedCustomers = this.customers.sort(
      (a, b) => b.invoiceCount - a.invoiceCount,
    );
    this.masterCustomer = sortedCustomers[0].id;
  }

  mergeCustomers(): void {
    const copies = this.customers
      .filter((c) => c.id !== this.masterCustomer)
      .map((c) => c.id);
    this.$emit('merge', { master: this.masterCustomer, copies });
    this.$emit('close');
  }
}
